import "./About.scss";
import { SiPostgresql, SiExpress, SiReact } from "react-icons/si";
import { DiNodejsSmall } from "react-icons/di";

export default function About() {
  return (
    <div className="about" id="about">
      <div className="left">
        <a href="https://www.linkedin.com/in/bradleymorabito">
          <img src="assets/linkedin-badge.png" alt="linkedin profile link" />
        </a>
        <h2> Connect with me on LinkedIn</h2>
      </div>
      <div className="right">
        <h1>About me</h1>
        <hr></hr>
        <p>
          I am a software engineer developing applications with a PostgreSQL,
          Express, React, and Node (PERN) stack. I also provide research
          consulting services through the Junkin Group, LLC. When I'm not
          working with data or code, I sling cocktails at Bluebird Distilling
          and produce dance music.
        </p>
        <div className="tech-stack">
          <td className="icon">
            <SiPostgresql />
          </td>

          <td className="icon">
            <SiExpress />
          </td>

          <td className="icon">
            <SiReact />
          </td>

          <td className="icon">
            <DiNodejsSmall />
          </td>
        </div>
        <tr>
          <td className="table-head">Additional Tools: </td>
          <td className="table-detail">
            Mocha, Chai, Git, GitHub, Heroku, Vercel, Node Package Manager (NPM)
          </td>
        </tr>
        <tr>
          <td className="table-head">Proficiencies: </td>
          <td className="table-detail">
            Unit Testing, Agile, Scrum, Data Analysis, Report Writing, Fluent in
            German
          </td>
        </tr>
      </div>
    </div>
  );
}
