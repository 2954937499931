import "./Contact.scss";
import { FaHandshake, FaLinkedin, FaEnvelope, FaGithub } from "react-icons/fa";
import { useState } from "react";

export default function Contact() {
  const [message, setMessage] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage(true);
  };
  return (
    <div className="contact" id="contact">
      <div className="left">
        <div className="socials">
          <a href="https://www.linkedin.com/in/bradleymorabito">
            <FaLinkedin />
          </a>
          <a href="mailto:bcbmorabito@gmail.com">
            <FaEnvelope />
          </a>
          <a href="https://github.com/bcbmorabito">
            <FaGithub />
          </a>
        </div>
        <h1>Let's get in touch</h1>
        <div className="icon">
          <FaHandshake />
        </div>
      </div>
      <div className="right">
        <h1>Contact me</h1>
        <hr />
        <form onSubmit={handleSubmit}>
          <input type="text" placeholder=" Name" />
          <input type="text" placeholder=" Email" />
          <textarea placeholder=" Message"></textarea>
          <button type="submit">Send</button>
        </form>
        {message && <span>Thanks for your message! I will respond soon.</span>}
      </div>
    </div>
  );
}
