import About from "./components/about/About";
import Intro from "./components/intro/Intro";
import NavBar from "./components/navbar/NavBar";
import Menu from "./components/menu/Menu";
// import Portfolio from "./components/portfolio/Portfolio";
import Contact from "./components/contact/Contact";
import './App.scss';
import { useState } from 'react';
// import Experience from "./components/experience/Experience";


function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="app">
      <NavBar  menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
      <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
        <div className="sections">
          <Intro />
          <About />
          {/* <Experience /> */}
          {/* <Portfolio /> */}
          <Contact /> 
        </div>
    </div>
  );
}

export default App;
