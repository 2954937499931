import { FaHome, FaIdCard, FaEnvelope } from "react-icons/fa";
import "./Menu.scss";

export default function Menu({ menuOpen, setMenuOpen }) {
  return (
    <div className={"menu " + (menuOpen && "active")}>
      <ul>
        <li onClick={() => setMenuOpen(false)}>
          <FaHome />
          <a href="#intro">Home</a>
        </li>
        <li onClick={() => setMenuOpen(false)}>
          <FaIdCard />
          <a href="#about">About me</a>
        </li>
        {/* <li onClick={() => setMenuOpen(false)}>
          <FaGlobeAfrica />
          <a href="#experience">Experience</a>
        </li> */}
        {/* <li onClick={() => setMenuOpen(false)}>
          <FaCode />  
          <a href="#portfolio">Recent Projects</a>
        </li> */}
        <li onClick={() => setMenuOpen(false)}>
          <FaEnvelope />
          <a href="#contact">Contact</a>
        </li>
        <li onClick={() => setMenuOpen(false)}>
          <button>
            <a href="assets/Bradley-Morabito-Resume.pdf">Download Resume</a>
          </button>
        </li>
      </ul>
    </div>
  );
}
